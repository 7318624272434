import '../scss/app.scss'

const data_company_selector = document.querySelector('.data_company')
if (data_company_selector) {
    async function data_company() {
        return await import('./components/data_company')
    }

    data_company()
        .then((r) => {
            r.activeClassOnLabel(data_company_selector)
            r.openModal(data_company_selector)
            r.resetSecondaryFields(data_company_selector)
        })
        .catch((r) => {
            console.log(r)
        })
}

const navbar_selector = document.querySelector('.navbar')
if (navbar_selector) {
    async function navbar() {
        return await import('./components/navbar')
    }

    navbar()
        .then((r) => {
            r.toggleNavbarBurger(navbar_selector)
        })
        .catch((r) => {
            console.log(r)
        })
}

const search_selector = document.querySelectorAll('.search-input')
if (search_selector.length > 0) {
    const search = async () => {
        return await import('./components/search-input')
    }

    search_selector.forEach((item) => {
        search()
            .then((r) => {
                r.searchResults(item)
            })
            .catch((r) => {
                console.log(r)
            })
    })
}

const slider_selector = document.querySelectorAll('.slider')
if (slider_selector.length > 0) {
    const slider = async () => {
        return await import('./components/slider')
    }

    slider_selector.forEach((item) => {
        slider()
            .then((r) => {
                r.initSlider(item)
            })
            .catch((r) => {
                console.log(r)
            })
    })
}

const sliderCategory_selector = document.querySelectorAll('.slider--category')
if (sliderCategory_selector.length > 0) {
    const slider = async () => {
        return await import('./components/slider-category')
    }

    sliderCategory_selector.forEach((item) => {
        slider()
            .then((r) => {
                r.initSlider(item)
            })
            .catch((r) => {
                console.log(r)
            })
    })
}

const toolbar_selector = document.querySelector('.toolbar')
if (toolbar_selector) {
    const toolbar = async () => {
        return await import('./components/toolbar')
    }

    toolbar()
        .then((r) => {
            r.toggleToolbar(toolbar_selector)
            r.checkAllCategories(toolbar_selector)
        })
        .catch((r) => {
            console.log(r)
        })
}

const video_selector = document.querySelectorAll('.video')
if (video_selector) {
    async function loadVideo() {
        return await import('./components/video')
    }

    loadVideo().then((r) => {
        video_selector.forEach((item) => {
            const video = new r.Video(item)
            video.load()
        })
    })
}
